<template>
  <v-row>
    <v-col cols="8">
      <ItemGrid :items="items" />
    </v-col>
    <v-col cols="4" />
  </v-row>
</template>

<script>

import ItemGrid from '@/components/item/ItemGrid.vue'

export default {
    name: 'Order',
    components: {
        ItemGrid
    },
    data() {
        return {
            orderUUID: null
        }
    },
    computed: {
        items() {
            return this.$store.state.items
        }
    },
    created() {
        this.orderUUID = this.$route.params.uuid
    }
}
</script>
