<template>
  <v-container>
    <v-row>
      <template v-for="item in items">
        <v-col
          :key="item.uuid"
          md="6"
          sm="6"
          cols="3"
        >
          <ItemGridItem :item="item" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

import ItemGridItem from './ItemGridItem'

export default {
    name: 'ItemGrid',
    props: {
      items: {
        type: Array, 
        default: () => []
      }
    },
    components: {
        ItemGridItem
    }
}
</script>