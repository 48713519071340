<template>
  <v-card link>
    <v-img
      :src="imageSrc"
      class="white--text grey align-end"
      height="200px"
    >
      <div style="background-color: rgba(10, 10, 10, 0.6) !important;">
        <v-card-title
          class="text-subtitle-1 font-weight-black text-no-wrap text-truncate"
          v-text="item.title"
        />
        <v-card-subtitle class="text-subtitle-2 white--text">
          {{ item.price.amount }} {{ item.price.currency_code }}
        </v-card-subtitle>
      </div>
    </v-img>
  </v-card>
</template>
<script>
export default {
    name: 'ItemGridItem',
    props: {
      item: {
        type: Object, 
        default: () => {}
      }
    },
    computed: {
        imageSrc() {

            if(this.item.images !== null && this.item.images !== undefined && this.item.images.length > 0) {
                return this.item.images[0].file.sources.medium
            }

            return null
        }
    }
}
</script>